function HotelPageListItem(props) {
    return (
        <li>
            <h2>
                <a href={props.hotel.Url}>
                    {props.hotel.Name}
                </a>
            </h2>
            <div className="tour">
                <img className="tourImage" src={props.hotel.MainImage.Url + "?width=300&height=200"} alt={props.hotel.Name} />
                <div className="tourInfo">
                    <p dangerouslySetInnerHTML={{ __html: props.hotel.Description.substring(0,200) }} ></p>
                    <p>You save <span className="yousave">{props.hotel.YouSave}%</span></p>
                    <a href={props.hotel.Url}>Book now</a>
                </div>
            </div>
        </li>
    )
};
export default HotelPageListItem;