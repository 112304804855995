import {useEffect, useState} from "react";
import axios from "axios";
import emailAddresses from "email-addresses";

const isLocal = false;

function getName(cust) {
    if (cust.firstname) return cust.firstname;
    else if (cust.name) return cust.name;
    else if (cust.email) return cust.email;
    else return "";
}

function CustomerInfo() {
    //   const Axios = () => {
    const odpMeApi = "https://www.alloytours.com/api/odp/me";
    //     const [data, setOdpData] = useState([]);

    //     useEffect(() => {
    //       axios.get(odpMeApi).then((res) => {
    //         setOdpData(res.data);
    //         console.debug(res.data);
    //       });
    //     }, []);
    //   };
    const [data, setOdpData] = useState([]);

    const fetchInfo = () => {
        const dummyData = {
            name: "Steve Celius",
            email: "steve@optimizely.no",
            hasConsent: false,
            firstname: "Steve",
            surname: "Celius",
            isKnown: true,
        };

        const dummyDataOnlyEmail = {
            name: "",
            email: "steve@opti.no",
            hasConsent: false,
            firstname: "",
            surname: "",
            isKnown: false,
        };

        if (isLocal) {
            setOdpData(dummyData);
        } else {
            return axios.get(odpMeApi, { withCredentials: true }).then((response) => {
                setOdpData(response.data);
                console.debug(response.data);
            }).catch((reason) => {
                    console.log(reason);
                    setOdpData(dummyDataOnlyEmail);
                }
            );
        }
    };

    useEffect(() => {
        fetchInfo();
    }, []);
    if (data.isKnown) {
        return <div>Welcome <a href="https://www.alloytours.com/en/my-page/">{getName(data)}</a></div>;
    } else if (data.email){
        const email = emailAddresses.parseOneAddress(data.email);
        if(email != null){
            return <div>Hello <a href="https://www.alloytours.com/en/my-page/">{email.local}</a></div>;
        }
    }
}

export default CustomerInfo;
