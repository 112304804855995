import logo2 from "./img/AlloyToursMembers-white.png";
import CustomerInfo from "./CustomerInfo";
import LoadingScreen from "./Loading";

function Header(props) {
    const showLoading= props.showLoading ?? false;
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo2} alt="Alloy Tours Logo" className="App-logo"/>
                <CustomerInfo/>
            </header>
            { showLoading && 
                <LoadingScreen message={props.message}/>
            }
        </div>
    );
}
export default Header;