import "./App.css";
import {useQuery, gql} from "@apollo/client";
import TourPageListItem from "./TourPageListItem";
import Header from "./Header";
import HotelPageListItem from "./HotelPageListItem";

const GET_TOURS = gql`
    {
        TourPage(locale: en, orderBy: { Votes: DESC },
            where: {BadgeSavePercent: {gt: 0}}) {
            items {
                _id
                Name
                Url
                Description
                Votes
                MainImage {
                    Url
                }
                YouSave: BadgeSavePercent
            }
        }
        HotelPage(locale: en, orderBy: { Votes: DESC },
            where: {BadgeSavePercent: {gt: 0}}) {
            items {
            _id
            Name
            Url
            Description
            Votes
            MainImage {
                Url
            }
            YouSave: BadgeSavePercent
            }
        }
            
    }
`;

function App() {
    const {data, loading, error} = useQuery(GET_TOURS);

    if (loading) {
        return <Header message={"Checking for great offers."} showLoading={true}/>;
    }

    if (error) return <pre>{error.message}</pre>;

    return (
        <div className="App">
            <Header showLoading={false}/>
            <main>

                <h1>Top deals for members:</h1>
                <h2>Hotels</h2>
                <ul className="tourlist">
                    {data.HotelPage.items.map((hotel) => (
                        <HotelPageListItem key={hotel._id} hotel={hotel}/>
                    ))}
                </ul>

                <h2>Tours</h2>
                <ul className="tourlist">
                    {data.TourPage.items.map((tour) => (
                        <TourPageListItem key={tour._id} tour={tour}/>
                    ))}
                </ul>
                
            </main>
        </div>
    );
}

export default App;
