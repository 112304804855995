import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import App from './App';
import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
  uri: "https://cg.optimizely.com/content/v2?auth=gJUZKLRlq4zlyFqjlnHhoZ1Sn5RFjz0lZKXZDEotWJLYAbx0",
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

